import { useQuery } from '@clientos/graphql-client/dist/react';
import {
  Loading,
  LookingForSomethingElse
} from '@hpx-core-experiences/react-my-account-commons/dist/index';
import { Button, IconGhostWaveWall, IconReload } from '@veneer/core';
import React, { useEffect, useMemo, useState } from 'react';
import ErrorBoundary from 'src/App/ErrorBoundary';
import DeviceCard from 'src/components/DeviceCard';
import { DoNotSeeDevice } from 'src/components/DoNotSeeDevice';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';
import GET_DEVICES from 'src/graphql/getDevices';
import { ContainerContent, Section } from 'src/styles/styles';
import { Device } from 'src/types/IDevices';
import {
  DevicesError,
  DevicesScreenDisplayed,
  RefreshDevicesButtonClicked,
  ScreenName,
  publishEvent,
  screenPath
} from 'src/utils/analytics';
import { DeviceType, DeviceTypeParam, FeatureFlagsEnum } from 'src/utils/enums';
import {
  DevicesContainer,
  DevicesList,
  Header,
  NoDevicesContent,
  NoDevicesSection,
  RefreshSection
} from './styles';
import useFeatureFlags from 'src/hooks/useFeatureFlags';

const Devices = () => {
  const { translate, northboundAPIs } = useDependencyManagerContext();
  const [refetchLoading, setRefetchLoading] = useState(false);
  const { loading, error, data, refetch } = useQuery(GET_DEVICES, {
    variables: {
      limit: 200,
      deviceType: [DeviceTypeParam.PRINTER, DeviceTypeParam.PC]
    }
  });

  const navigation = northboundAPIs.v1.navigation;
  const country = northboundAPIs.v1.localization.country;
  const language = northboundAPIs.v1.localization.language;

  const {
    featureFlagValue: originalFeedbackExperience,
    isLoading: isOriginalFeedbackExperienceLoading
  } = useFeatureFlags(FeatureFlagsEnum.originalFeedbackExperience);

  const showOriginalFeedbackExperience =
    originalFeedbackExperience && !isOriginalFeedbackExperienceLoading;

  useEffect(() => {
    if (!loading && data) {
      const DeviceCt = getAnalyticsData(data.devices?.items);
      publishEvent(DevicesScreenDisplayed(DeviceCt));
    }
  }, [loading, data]);

  const getAnalyticsData = (devices: Device[]) => {
    const deviceTypeMapping: { [key: string]: DeviceType } = {
      PrinterDevice: DeviceType.PRINTER_DEVICE,
      PCDevice: DeviceType.PC_DEVICE
    };

    const totalPrinter = devices?.filter(
      (item: Device) =>
        deviceTypeMapping[item.__typename] === DeviceType.PRINTER_DEVICE
    ).length;

    const totalPCDevice = devices?.filter(
      (item: Device) =>
        deviceTypeMapping[item.__typename] === DeviceType.PC_DEVICE
    ).length;
    const totalDevice = totalPrinter + totalPCDevice;

    return `totalDeviceCt=${totalDevice}&totalPrinterCt=${totalPrinter}&totalPcCt=${totalPCDevice}`;
  };

  const renderDevices = useMemo(() => {
    const refreshButton = () => {
      return (
        <RefreshSection>
          <div>
            <Button
              data-testid="refresh-button"
              appearance="ghost"
              leadingIcon={<IconReload />}
              loading={refetchLoading}
              onClick={() => {
                setRefetchLoading(true);
                publishEvent(RefreshDevicesButtonClicked);
                refetch().finally(() => {
                  setRefetchLoading(false);
                });
              }}
            >
              {`${translate('common.refresh', 'Refresh')}`}
            </Button>
          </div>
        </RefreshSection>
      );
    };

    const noDevices = () => {
      return (
        <NoDevicesSection
          appearance="outlined"
          data-testid="no-devices-section"
          content={
            <NoDevicesContent>
              <IconGhostWaveWall size={56} />
              <span>{`${translate('devices.noDevicesYet', 'No devices yet!')}`}</span>
            </NoDevicesContent>
          }
        />
      );
    };

    const deviceList = () => {
      return (
        <DevicesList>
          {data?.devices?.items?.map((item: Device) => (
            <DeviceCard
              key={item.deviceId}
              item={item}
              onClick={() =>
                navigation.push(
                  `/${country}/${language}/devices/${item.deviceId}`
                )
              }
            />
          ))}
        </DevicesList>
      );
    };

    const noDevicesFound = data?.devices?.items?.length <= 0;

    return (
      <DevicesContainer>
        {noDevicesFound ? noDevices() : deviceList()}
        {refreshButton()}
      </DevicesContainer>
    );
  }, [
    country,
    data?.devices?.items,
    language,
    navigation,
    refetch,
    refetchLoading,
    translate
  ]);

  return (
    <>
      <Header data-testid="header-devices">
        <div className="title-regular">{`${translate('menu.devices', 'Devices')}`}</div>
        <p className="body">
          {`${translate(
            'devices.viewOrManageCurrentDevices',
            'View or manage your current HP devices'
          )}`}
        </p>
      </Header>
      <ContainerContent>
        {loading && (
          <Section
            appearance="outlined"
            content={<Loading />}
          />
        )}
        {error && (
          <ErrorBoundary analyticsEvent={DevicesError(error.message)} />
        )}
        {!loading && !error && renderDevices}
        <DoNotSeeDevice />
      </ContainerContent>
      {showOriginalFeedbackExperience && (
        <LookingForSomethingElse
          analytics={{
            screenName: ScreenName.DEVICES,
            screenPath: screenPath
          }}
          translate={translate}
        />
      )}
    </>
  );
};
export default Devices;
